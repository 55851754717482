<template>
  <ValidationObserver
    ref="observer"
    tag="div"
    class="form"
    v-slot="{ handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(login)">
      <ValidationProvider
        v-slot="{ errors }"
        class="mb-2"
        tag="div"
        rules="required|email"
      >
        <BaseInput
          type="email"
          label="Email"
          name="email"
          v-model="email"
          autocomplete="email"
          placeholder="Email"
        />
        <p class="text-red-600" v-if="errors[0]">Email is required</p>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        class="mb-2"
        tag="div"
        rules="required"
      >
        <BaseInput
          type="password"
          label="Password"
          name="password"
          v-model="password"
          placeholder="Password"
          class="mb-4"
        />
        <p class="text-red-600" v-if="errors[0]">Email is required</p>
      </ValidationProvider>
      <div class="flex justify-between">
        <BaseBtn type="submit" text="Login" class="" />
        <router-link to="/forgot-password" class="text-sm base-link">
          Forgot your password?
        </router-link>
      </div>
      <FlashMessage :error="error" />
    </form>
  </ValidationObserver>
</template>

<script>
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import BaseInput from "@/components/BaseInput";
import AuthService from "@/services/AuthService";
import FlashMessage from "@/components/FlashMessage";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default {
  name: "LoginView",
  components: {
    BaseBtn,
    BaseInput,
    FlashMessage,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: null,
      password: null,
      error: null,
    };
  },
  methods: {
    async login() {
      const payload = {
        email: this.email,
        password: this.password,
      };
      this.error = null;
      try {
        await AuthService.login(payload);
        const authUser = await this.$store.dispatch("auth/getAuthUser");
        if (authUser) {
          this.$store.dispatch("auth/setGuest", { value: "isNotGuest" });
          this.$router.push("/curriculum");
        } else {
          const error = Error(
            "Unable to fetch user after login, check your API settings."
          );
          error.name = "Fetch User";
          throw error;
        }
      } catch (error) {
        this.error = getError(error);
      }
    },
  },
};
</script>
