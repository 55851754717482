<template>
  <div class="max-w-sm p-5 m-auto">
    <h2 class="mb-4 text-3xl text-center">themathsplatform</h2>
    <LoginForm class="p-5 bg-white border rounded" />
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm";

export default {
  name: "LoginView",
  components: {
    LoginForm,
  },
};
</script>
